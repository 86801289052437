// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapDocumentStatus } from "@/_models/dap-common";
import { DapTemplateContentBusinessState } from "@/_models/dap-document-details";

export class DapStatusHelper {
    public static getFlowchartDefinition(businessStates: DapTemplateContentBusinessState[], currDocStatus: DapDocumentStatus,
        currBusinessState: string, userCanEditDocument: boolean): string {

        // templateName is the string that is used in the template to reference a particular document state e.g. ReadyForApproval
        const docStates: { nodeId: string, templateName: string, text: string, tooltip: string }[] = [];
        docStates.push({ nodeId : 'DS1', templateName: 'Draft', text: 'Draft', tooltip: '' });
        docStates.push({ nodeId : 'DS2', templateName: 'Open', text: 'Open', tooltip: '' });
        docStates.push({ nodeId : 'DS3', templateName: 'ReadyForApproval', text: 'Ready for Approval',
            tooltip: currDocStatus === DapDocumentStatus.Approval ? 'Remove the last of the existing approvals' : ''});
        docStates.push({ nodeId : 'DS4', templateName: 'Approval', text: 'Approval',
            tooltip: currDocStatus === DapDocumentStatus.ReadyForApproval
                ? 'Document must be approved by at least one approval role'
                : (currDocStatus === DapDocumentStatus.Approved ? 'Remove one of the existing approvals' : '')});
        docStates.push({ nodeId : 'DS5', templateName: 'Approved', text: 'Approved',
                tooltip: currDocStatus === DapDocumentStatus.Approval ? 'Document must be approved by all approval roles' : ''});
        docStates.push({ nodeId : 'DS6', templateName: 'Active', text: 'Active', tooltip: ''});
        docStates.push({ nodeId : 'DS7', templateName: 'Completed', text: 'Completed',
                tooltip: currDocStatus === DapDocumentStatus.Active ? 'All Sections and containing DAPs must be completed' : ''});
        docStates.push({ nodeId : 'DS8', templateName: 'Abandoned', text: 'Abandoned', tooltip: ''});
        let docStateNodes = '';
        docStates.forEach(docState => {
            const className = docState.templateName === DapDocumentStatus[currDocStatus] ? 'currState' : 'docState';
            docStateNodes += `\n${docState.nodeId}([<div title='${docState.tooltip}'>${docState.text}</div>]):::${className}`;
        });

        let businessStateNodes = '';
        let initialBizStateId = '';
        let bizToDocStateLinks = '';
        let bizToDocStateLinksCount = 0;
        let docToBizStateAutoTransitions = '';
        businessStates.forEach((bizState, index) => {
            const bizStateId = `BS${index}`;
            if (bizState.initial) { initialBizStateId = bizStateId; }
            const className = bizState.name === currBusinessState ? 'currState' : 'bizState';
            businessStateNodes += `\n${bizStateId}[<div title='${bizState.description}'>${bizState.name}</div>]:::${className}`;

            // allowed BusinessStates based on DocState
            if (bizState.inDocState && bizState.inDocState.length > 0) {
                const inDocStateCount = bizState.inDocState.length;
                bizToDocStateLinksCount += inDocStateCount;
                let inDocStateLinks = '';
                bizState.inDocState.forEach((inDocState, inDocStateIndex) => {
                    const docStateId = docStates.find(x => x.templateName == inDocState).nodeId;
                    inDocStateLinks += inDocStateIndex === inDocStateCount - 1 ? `${docStateId}` : `${docStateId} & `;
                });
                bizToDocStateLinks += `\n${inDocStateLinks} -.- ${bizStateId}`;
            }

            // DocState to BusinessState (auto) transitions
            if (bizState.onDocStateChange && bizState.onDocStateChange.length > 0) {
                let onDocStateChangeLinks = '';
                bizState.onDocStateChange.forEach((onDocStateChange, onDocStateChangeIndex) => {
                    const docStateId = docStates.find(x => x.templateName == onDocStateChange).nodeId;
                    onDocStateChangeLinks += onDocStateChangeIndex === bizState.onDocStateChange.length - 1 ? `${docStateId}` : `${docStateId} & `;
                });
                docToBizStateAutoTransitions += `\n${onDocStateChangeLinks} ==> |auto| ${bizStateId}`;
            }
        });

        // Current BusinessState to BusinessState allowed (user) transitions
        let businessStateTransitions = '';
        let businessStateClickEvents = '';
        const allowedTransitions = DapStatusHelper.getAllowedBusinessStateTransitions(businessStates, currBusinessState, currDocStatus);
        if (allowedTransitions && allowedTransitions.length > 0) {
            let bizStateLinks = '';
            allowedTransitions.forEach((trans, transIndex) => {
                const bizStateId = `BS${businessStates.findIndex(x => x.name == trans.name)}`;
                bizStateLinks += transIndex === allowedTransitions.length - 1 ? `${bizStateId}` : `${bizStateId} & `;
                if (userCanEditDocument) {
                    businessStateClickEvents += `\nclick ${bizStateId} call handleBizStateClick("${trans.name}")`;
                }
            });
            const currBusinessStateId = `BS${businessStates.findIndex(x => x.name == currBusinessState)}`;
            businessStateTransitions += `\n${currBusinessStateId} ==> |user| ${bizStateLinks}`;
        }

        let linkIndices = '';
        for (let i = 0; i < bizToDocStateLinksCount; i++) {
            linkIndices += i === bizToDocStateLinksCount - 1 ? `${i}` : `${i},`;
        }
        const linkStyleValue = `linkStyle ${linkIndices} stroke:#500`;

        return `flowchart TD
%% styles the nodes
classDef initState fill:#0f0,color:#000
classDef termState fill:#d00,color:#fff
classDef bizState fill:#00f,color:#fff
classDef docState fill:#400,color:#fff
classDef currState fill:#D5FDD5,color:#000

%% biz states - with *description* as tooltip 
${businessStateNodes}

%% Initial state - not a state, just an indicator node
Init([Initial]):::initState

%% Deleted state - a pseudo-state, but clickable to delete the document
Deleted([<div id='DEL'>Deleted</div>]):::termState

%% doc states - hard coded 
${docStateNodes}

%% biz states restricted by *inDocState* 
${bizToDocStateLinks}

%% set the color of those restriction links (need to count the link indices for this)
${linkStyleValue}

%% initial biz state - should be exactly one marked *initial*
Init ==> ${initialBizStateId}

%% doc state transitions - these are hard coded
Init -.-> DS1
DS1 -.-> DS2 -.-> DS3 -.-> DS4 -.-> DS5 -.-> DS6
DS6 -.-> DS7 & DS8
DS7 -.-> DS8
DS4 -.-> DS3
DS5 -.-> DS4
DS1 & DS2 & DS3 & DS4 & DS5 & DS8 -.-> Deleted

%% biz stated activated by doc state changes - *onDocStateChange* 
${docToBizStateAutoTransitions}

%% biz state restricted by previous biz state - *inBusinessState* 
${businessStateTransitions}

%% biz state click events 
${businessStateClickEvents}

%% doc state click events 
${userCanEditDocument ? DapStatusHelper.getDocStateClickEvent(currDocStatus) : ''}`;
    }

    private static getAllowedBusinessStateTransitions(businessStates: DapTemplateContentBusinessState[],
        currBusinessState: string, currDocStatus: DapDocumentStatus): DapTemplateContentBusinessState[] {

        return businessStates.filter(bs => {
            if (bs.initial) { return false; }
            if (bs.name === currBusinessState) { return false; }
            if (bs.inDocState && !bs.inDocState.some(ds => DapDocumentStatus[ds] === currDocStatus)) {
                return false;
            }
            if (bs.inBusinessState && !bs.inBusinessState.some(b => b === currBusinessState)) {
                return false;
            }
            return true;
        })
    }

    private static getDocStateClickEvent(currDocStatus: DapDocumentStatus): string {
        switch (currDocStatus) {
            case DapDocumentStatus.Draft:
                return `click DS2 call handleDocStatusClick(${DapDocumentStatus.Open}, "Open")`;
            case DapDocumentStatus.Open:
                return `click DS3 call handleDocStatusClick(${DapDocumentStatus.ReadyForApproval}, "Ready for Approval")`;
            case DapDocumentStatus.Approved:
                return `click DS6 call handleDocStatusClick(${DapDocumentStatus.Active}, "Active")`;
            default:
                return '';
        }
    }
}
