// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapTemplateContentColumn } from "@/_models/dap-document-details";
import { DapDocumentValueValidators, MultiValueValidatorFunc, ValueValidatorFunc } from "../dap-document-value-validators";
import { RefValidation } from "../eval/ref-validation";
import { DapDocumentVM, DapTableRowValue, DispositionResult } from "../vm";
import { PickListItem, PickListType } from "@/_models";

export class DapTableHelper {

    public static getColumnValueValidatorFuncs(col: DapTemplateContentColumn, columnDispositionResults: Map<string, DispositionResult>,
        docVm: DapDocumentVM, sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue,
        picklistMap: Map<PickListType, PickListItem[]>): ValueValidatorFunc[] {

        const result: ValueValidatorFunc[] = [];
    
        const dispositionResult = columnDispositionResults.get(col.key);
        if (dispositionResult && !dispositionResult.nullable) {
            result.push(DapDocumentValueValidators.valueRequiredFunc(col.format));
        }
    
        if (col.fieldValidation) {
            result.push(...DapTableHelper.getValidatorsFromFunctions(col, docVm, sectionIndex, dapIndex, rowValue, picklistMap));
        }
    
        return result;
    }

    private static getValidatorsFromFunctions(col: DapTemplateContentColumn, docVm: DapDocumentVM, sectionIndex: number, dapIndex: number,
        rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>): ValueValidatorFunc[] {

        const result: ValueValidatorFunc[] = [];
        col.fieldValidation?.forEach(validatorFunc => {
            result.push(...RefValidation.getTableCellValidatorFuncs(validatorFunc, col.format, docVm, sectionIndex, dapIndex, rowValue, picklistMap))
        });

        return result;
    }

    public static getColumnMultiValueValidatorFuncs(col: DapTemplateContentColumn): MultiValueValidatorFunc[] {
        const result: MultiValueValidatorFunc[] = [];
    
        if (col.unique) {
            result.push(DapDocumentValueValidators.valueUniqueFunc(col.format));
        }
    
        return result;
    }
}
