// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action, Store } from '../ngrx-actions';
import {
    AddInStoreDapDocumentContactAction, ClearDocumentDetailsStateAction, DeleteInStoreDapDocumentContactAction,
    LoadAdGroupsAction, LoadDapDocumentApprovalsAction, LoadDapDocumentBusinessStateAction, LoadDapDocumentChangesAction, LoadDapDocumentContactsAction,
    LoadDapDocumentContentAction, LoadDapDocumentPropertiesAction, LoadDapDocumentStatusAction,
    LoadDapTemplateContentAction, LoadPicklistItemsAction, LoadScoringModelDefaultsAction, UpdateInStoreDapDocumentContactAction
} from './dap-document-details.actions';
import { DapDocumentDetailsState } from './dap-document-details.state';

@Store({} as DapDocumentDetailsState)
export class DapDocumentDetailsStore {

    @Action(LoadDapTemplateContentAction)
    public loadTemplateContent(state: DapDocumentDetailsState, { templateContent }: LoadDapTemplateContentAction) {
        return { ...state, templateContent } as DapDocumentDetailsState;
    }

    @Action(LoadDapDocumentPropertiesAction)
    public loadDocumentProperties(state: DapDocumentDetailsState, { docProperties }: LoadDapDocumentPropertiesAction) {
        return { ...state, docProperties } as DapDocumentDetailsState;
    }

    @Action(LoadDapDocumentChangesAction)
    public loadDocumentChanges(state: DapDocumentDetailsState, { documentChanges }: LoadDapDocumentChangesAction) {
        return { ...state, documentChanges } as DapDocumentDetailsState;
    }

    @Action(LoadDapDocumentContentAction)
    public loadDocumentContent(state: DapDocumentDetailsState, { documentContent }: LoadDapDocumentContentAction) {
        return { ...state, documentContent } as DapDocumentDetailsState;
    }

    @Action(LoadDapDocumentApprovalsAction)
    public loadDocumentApprovals(state: DapDocumentDetailsState, { approvals }: LoadDapDocumentApprovalsAction) {
        return { ...state, approvals } as DapDocumentDetailsState;
    }

    @Action(LoadDapDocumentContactsAction)
    public loadDocumentContacts(state: DapDocumentDetailsState, { contacts }: LoadDapDocumentContactsAction) {
        return { ...state, contacts } as DapDocumentDetailsState;
    }

    @Action(AddInStoreDapDocumentContactAction)
    public addDocumentContact(state: DapDocumentDetailsState, { contact }: AddInStoreDapDocumentContactAction) {
        if (state.contacts != null) {
            const newContacts = [...state.contacts];
            newContacts.push(contact);

            return { ...state, contacts: newContacts } as DapDocumentDetailsState;
        }
    }

    @Action(UpdateInStoreDapDocumentContactAction)
    public updateDocumentContact(state: DapDocumentDetailsState, { contact }: AddInStoreDapDocumentContactAction) {
        if (state.contacts != null) {
            const index = state.contacts.findIndex(x => x.id === contact.id);
            if (index >= 0) {
                const newContacts = [...state.contacts];
                newContacts[index] = { ...contact };
                return { ...state, contacts: newContacts } as DapDocumentDetailsState;
            }
        }
    }

    @Action(DeleteInStoreDapDocumentContactAction)
    public deleteDocumentContact(state: DapDocumentDetailsState, { id }: DeleteInStoreDapDocumentContactAction) {
        if (state.contacts != null) {
            const newContacts = [...state.contacts];
            const index = newContacts.findIndex(x => x.id === id);
            if (index >= 0) { newContacts.splice(index, 1); }

            return { ...state, contacts: newContacts } as DapDocumentDetailsState;
        }
    }

    @Action(LoadDapDocumentStatusAction)
    public loadDocumentStatus(state: DapDocumentDetailsState, { status }: LoadDapDocumentStatusAction) {
        if (status == null) { return; }
        return { ...state, status } as DapDocumentDetailsState;
    }

    @Action(LoadDapDocumentBusinessStateAction)
    public loadDocumentBusinessState(state: DapDocumentDetailsState, { businessState }: LoadDapDocumentBusinessStateAction) {
        return { ...state, businessState } as DapDocumentDetailsState;
    }

    @Action(LoadPicklistItemsAction)
    public loadPicklistItems(state: DapDocumentDetailsState, { picklistItems }: LoadPicklistItemsAction) {
        return { ...state, picklistItems } as DapDocumentDetailsState;
    }

    @Action(LoadAdGroupsAction)
    public loadAdGroups(state: DapDocumentDetailsState, { adGroups }: LoadAdGroupsAction) {
        return { ...state, adGroups } as DapDocumentDetailsState;
    }

    @Action(LoadScoringModelDefaultsAction)
    public loadScoringModelDefaults(state: DapDocumentDetailsState, { defaults }: LoadScoringModelDefaultsAction) {
        return { ...state, scoringModelDefaults: defaults } as DapDocumentDetailsState;
    }

    @Action(ClearDocumentDetailsStateAction)
    public clearDocumentDetailsState() {
        return new DapDocumentDetailsState();
    }
}
