// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { PickListItem } from '@/_models';
import { DapDocumentStatus } from '@/_models/dap-common';
import {
    DapDocumentApproval, DapDocumentChangeSet, DapDocumentContact, DapDocumentContent, DapDocumentDetailsData, DapDocumentProperties, DapScoringModelDefaults, DapTemplateContent
} from '@/_models/dap-document-details';
import { EditingMonitoringSession } from '@/_models/editing-monitoring-session';
import { Action } from '@ngrx/store';

export class LoadDapTemplateContentAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Template Content action';

    constructor(public readonly templateContent: DapTemplateContent) { }
}

export class LoadDapDocumentPropertiesAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Properties action';

    constructor(public readonly docProperties: DapDocumentProperties) { }
}

export class LoadDapDocumentChangesAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Changes action';

    constructor(public readonly documentChanges: DapDocumentChangeSet[]) { }
}

export class LoadDapDocumentContentAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Content action';

    constructor(public readonly documentContent: DapDocumentContent) { }
}

export class LoadDapDocumentStatusAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Status action';

    constructor(public readonly status: DapDocumentStatus) { }
}

export class LoadDapDocumentBusinessStateAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Business State action';

    constructor(public readonly businessState: string) { }
}

export class LoadDapDocumentApprovalsAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Approvals action';

    constructor(public readonly approvals: DapDocumentApproval[]) { }
}

export class ClearDocumentDetailsStateAction implements Action {
    public type = '[DapDocumentDetails] Clear Dap document details state action';
}

//#region fetch

export class FetchDapTemplateContentAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapTemplateContent action';
    constructor(public readonly id: number) { }
}
export class FetchDapTemplateContentOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapTemplateContent Ok action';
}
export class FetchDapTemplateContentFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapTemplateContent Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchDapDocumentContentAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentContent action';
    constructor(public readonly id: number) { }
}
export class FetchDapDocumentContentOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentContent Ok action';
}
export class FetchDapDocumentContentFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentContent Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchDapDocumentDataAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentData action';
    constructor(public readonly id: number) { }
}
export class FetchDapDocumentDataOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentData Ok action';
}
export class FetchDapDocumentDataFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentData Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion fetch

//#region Save Document

export class SaveDapDocumentAction implements Action {
    public type = '[DapDocumentDetails] Save DapDocument action';
    constructor(
        public readonly id: number,
        public readonly documentDetails: DapDocumentDetailsData,
        public readonly monitoringSession: EditingMonitoringSession) { }
}
export class SaveDapDocumentOkAction implements Action {
    public type = '[DapDocumentDetails] Save DapDocument Ok action';
}
export class SaveDapDocumentFailedAction implements Action {
    public type = '[DapDocumentDetails] Save DapDocument Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Save Document

//#region Update Status

export class UpdateStatusAction implements Action {
    public type = '[DapDocumentDetails] Update Status action';
    constructor(public readonly documentId: number, public readonly newStatus: DapDocumentStatus) { }
}
export class UpdateStatusOkAction implements Action {
    public type = '[DapDocumentDetails] Update Status Ok action';
}
export class UpdateStatusFailedAction implements Action {
    public type = '[DapDocumentDetails] Update Status Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion Update Status

//#region Save Document Business Status
export class SaveDapDocumentBusinessStatusAction implements Action {
    public type = '[DapDocumentDetails] Save DapDocument Business Status action';
    constructor(
        public readonly documentId: number,
        public readonly businessState: string) { }
}
export class SaveDapDocumentBusinessStatusOkAction implements Action {
    public type = '[DapDocumentDetails] Save DapDocument Business Status Ok action';
}
export class SaveDapDocumentBusinessStatusFailedAction implements Action {
    public type = '[DapDocumentDetails] Save DapDocument Business Status Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion Save Document Business Status

//#region Approve Document

export class ApproveDapDocumentAction implements Action {
    public type = '[DapDocumentDetails] Approve DapDocument action';
    constructor(public readonly id: number, public readonly approval: DapDocumentApproval) { }
}
export class ApproveDapDocumentOkAction implements Action {
    public type = '[DapDocumentDetails] Approve DapDocument Ok action';
}
export class ApproveDapDocumentFailedAction implements Action {
    public type = '[DapDocumentDetails] Approve DapDocument Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Approve Document

//#region Clear Document Approval

export class ClearDapDocumentApprovalAction implements Action {
    public type = '[DapDocumentDetails] Clear DapDocument Approval action';
    constructor(public readonly id: number) { }
}
export class ClearDapDocumentApprovalOkAction implements Action {
    public type = '[DapDocumentDetails] Clear DapDocument Approval Ok action';
}
export class ClearDapDocumentApprovalFailedAction implements Action {
    public type = '[DapDocumentDetails] Clear DapDocument Approval Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Clear Document Approval

//#region Document Contacts

export class LoadDapDocumentContactsAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Dap Document Contacts action';

    constructor(public readonly contacts: DapDocumentContact[]) { }
}

export class AddInStoreDapDocumentContactAction implements Action {
    readonly type = '[DapDocumentDetails] InState Add Dap Document Contact action';

    constructor(public readonly contact: DapDocumentContact) { }
}

export class UpdateInStoreDapDocumentContactAction implements Action {
    readonly type = '[DapDocumentDetails] InState Update Dap Document Contact action';

    constructor(public readonly contact: DapDocumentContact) { }
}

export class DeleteInStoreDapDocumentContactAction implements Action {
    readonly type = '[DapDocumentDetails] InState Delete Dap Document Contact action';

    constructor(public readonly id: number) { }
}

export class FetchDapDocumentChangesAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentChanges action';
    constructor(public readonly id: number) { }
}
export class FetchDapDocumentChangesOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentChanges Ok action';
}
export class FetchDapDocumentChangesFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentChanges Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchDapDocumentContactsAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentContacts action';
    constructor(public readonly id: number) { }
}
export class FetchDapDocumentContactsOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentContacts Ok action';
}
export class FetchDapDocumentContactsFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch DapDocumentContacts Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CreateDapDocumentContactAction implements Action {
    public type = '[DapDocumentDetails] Create DapDocument Contact action';
    constructor(public readonly contact: DapDocumentContact) { }
}
export class CreateDapDocumentContactOkAction implements Action {
    public type = '[DapDocumentDetails] Create DapDocument Contact Ok action';
}
export class CreateDapDocumentContactFailedAction implements Action {
    public type = '[DapDocumentDetails] Create DapDocument Contact Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateDapDocumentContactAction implements Action {
    public type = '[DapDocumentDetails] Update DapDocument Contact action';
    constructor(public readonly contact: DapDocumentContact) { }
}
export class UpdateDapDocumentContactOkAction implements Action {
    public type = '[DapDocumentDetails] Update DapDocument Contact Ok action';
}
export class UpdateDapDocumentContactFailedAction implements Action {
    public type = '[DapDocumentDetails] Update DapDocument Contact Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class DeleteDapDocumentContactAction implements Action {
    public type = '[DapDocumentDetails] Delete DapDocument Contact action';
    constructor(public readonly id: number) { }
}
export class DeleteDapDocumentContactOkAction implements Action {
    public type = '[DapDocumentDetails] Delete DapDocument Contact Ok action';
}
export class DeleteDapDocumentContactFailedAction implements Action {
    public type = '[DapDocumentDetails] Delete DapDocument Contact Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Document Contacts

//#region Picklist Items

export class LoadPicklistItemsAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load Picklist Items action';

    constructor(public readonly picklistItems: PickListItem[]) { }
}

export class FetchPicklistItemsAction implements Action {
    public type = '[DapDocumentDetails] Fetch PicklistItems action';
}
export class FetchPicklistItemsOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch PicklistItems Ok action';
}
export class FetchPicklistItemsFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch PicklistItems Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Picklist Items

//#region AdGroups

export class LoadAdGroupsAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load AdGroups action';

    constructor(public readonly adGroups: string[]) { }
}

export class FetchAdGroupsAction implements Action {
    public type = '[DapDocumentDetails] Fetch AdGroups action';
}
export class FetchAdGroupsOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch AdGroups Ok action';
}
export class FetchAdGroupsFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch AdGroups Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion AdGroups

//#region ScoringModelDefaults

export class LoadScoringModelDefaultsAction implements Action {
    readonly type = '[DapDocumentDetails] InState Load ScoringModelDefaults action';

    constructor(public readonly defaults: DapScoringModelDefaults) { }
}

export class FetchScoringModelDefaultsAction implements Action {
    public type = '[DapDocumentDetails] Fetch ScoringModelDefaults action';
    constructor(public readonly id: number) { }
}
export class FetchScoringModelDefaultsOkAction implements Action {
    public type = '[DapDocumentDetails] Fetch ScoringModelDefaults Ok action';
}
export class FetchScoringModelDefaultsFailedAction implements Action {
    public type = '[DapDocumentDetails] Fetch ScoringModelDefaults Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion ScoringModelDefaults
