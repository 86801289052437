// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { CommonModule } from '@angular/common';
import { Component, forwardRef, NgModule, Input, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { Measure } from '@/_models';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-measure-input',
    template: `
<div class="d-flex">
    <input
        #valueInput
        class="form-control"
        type="number"
        [disabled]="isDisabled"
        [class.is-invalid]="isInvalid"
        (ngModelChange)="onMeasureValueChange($event)"
        [(ngModel)]="measureValue"
    />
    <ng-select
        *ngIf="units != null && units.length > 0"
        class="form-control flex-grow-1 ml-1 w-50"
        [class.is-invalid]="isInvalid"
        [title]="measureUoM"
        [disabled]="isDisabled || isUoMDisabled"
        [clearable]="false"
        [searchable]="false"
        [items]="units"
        (ngModelChange)="onMeasureUomChange($event)"
        [(ngModel)]="measureUoM"
    >
        <ng-template ng-option-tmp let-item="item">
            <span title="{{item}}">{{item}}</span>
        </ng-template>
    </ng-select>
    <input
        *ngIf="!(units != null && units.length > 0)"
        type="text"
        class="form-control flex-grow-1 ml-1 w-50"
        [class.is-invalid]="isInvalid"
        [title]="measureUoM"
        [disabled]="isDisabled || isUoMDisabled"
        (ngModelChange)="onMeasureUomChange($event)"
        [(ngModel)]="measureUoM"
    />
</div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeasureInputComponent),
            multi: true
        }
    ]
})
export class MeasureInputComponent implements ControlValueAccessor {
    @Input() public isInvalid = false;
    @Input() public units: string[] = null;
    @Input() public isUoMDisabled = false;
    public measureValue: number;
    public measureUoM: string;
    public isDisabled = false;

    @ViewChild('valueInput', { read: ElementRef }) public valueInput!: ElementRef;

    public onMeasureValueChange(newMeasureValue: number) {
        if (this.units != null && this.units.length > 0 && this.measureValue == null) {
            this.measureUoM = this.units[0];
        }
        this.onMeasureChange(newMeasureValue, this.measureUoM);
    }

    public onMeasureUomChange(newMeasureUom: string) {
        this.onMeasureChange(this.measureValue, newMeasureUom);
    }

    public onMeasureChange(measureValue: number, measureUom: string) {
        const newMeasure = measureValue == null ? null : new Measure(measureValue, measureUom);
        this.onChange(newMeasure);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public onChange = (_: Measure) => { };

    writeValue(measure: Measure): void {
        if (measure != null) {
            this.measureValue = measure.value;
            this.measureUoM = measure.uom;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

@NgModule({
    declarations: [MeasureInputComponent],
    exports: [MeasureInputComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ]
})
export class MeasureInputModule { }
